import { VerificationRecord } from 'api/domain';

export const getSiteLockedStatus = (
  site: { status?: string; status_reason?: number } | undefined
) => !!site && (site.status === 'locked' || site.status === 'pending') && site.status_reason === 5;

export const getMainDomainInfo = (site: any) => {
  const domain = site.domains.find((domain: any) => domain.maindomain)?.maindomain;

  return {
    domainType: domain?.domain_type ?? null,
    baseDomain: domain?.base_domain ?? null,
    subdomain: domain?.sub_domain ?? null,
    dnsProvider: domain?.dns_provider ?? null,
    sslStatus: domain?.ssl_status ?? null,
    hostnameStatus: domain?.hostname_status ?? null,
    validationMethod: domain?.validation_method ?? null,
    validationRecords: (domain?.validation_records || []) as VerificationRecord[],
    showGoLive: domain?.show_go_live || 0,
  };
};
